import React, { Suspense, lazy } from "react";
import Loading from "./components/Loading/Loading.js";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const HomePage = lazy(() => import("./components/HomePage/HomePage.js"));
const PrivacyPage = lazy(() =>
  import("./components/PrivacyPage/PrivacyPage.js")
);

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              <Suspense fallback={<Loading />}>
                <HomePage />
              </Suspense>
            }
          ></Route>
          <Route
            path="/privacidad"
            element={
              <Suspense fallback={<Loading />}>
                <PrivacyPage />
              </Suspense>
            }
          ></Route>
          <Route
            path="*"
            element={
              <Suspense fallback={<Loading />}>
                <HomePage />
              </Suspense>
            }
          ></Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
